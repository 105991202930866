import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
// import { css } from "styled-components/macro";
import { SectionHeading } from "../../../../ui-treact/components/misc/Headings.js";

import defaultCardImage from "../../../../ui-treact/images/shield-icon.svg";
import ScrollAnimation from 'react-animate-on-scroll';
import "src/theme/styles/animate.min.css"; 
// import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

// import SupportIconImage from "../../../../ui-treact/images/support-icon.svg";
// import ShieldIconImage from "../../../../ui-treact/images/shield-icon.svg";
// import CustomizeIconImage from "../../../../ui-treact/images/customize-icon.svg";
// import FastIconImage from "../../../../ui-treact/images/fast-icon.svg";
// import ReliableIconImage from "../../../../ui-treact/images/reliable-icon.svg";
// import SimpleIconImage from "../../../../ui-treact/images/simple-icon.svg";

import abTesting from "../../../custom-images/abTesting.png";
import affliateMarketing from "../../../custom-images/affliateMarketing.png";
import analytics from "../../../custom-images/analytics.png";
import customCart from "../../../custom-images/customCart.png";
import customization from "../../../custom-images/customization.png";
import emailMarketing from "../../../custom-images/emailMarketing.png";
import pageSpeed from "../../../custom-images/pagespeed.png";
import seo from "../../../custom-images/seo.png";
import digitalesign from "../../../custom-images/webDesign.png";
import { Link } from "../../../utils/general.js";

const Container = tw.div`relative w-full`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-8`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-0 flex justify-center items-stretch`}
`;

const Card = styled(Link)`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-4 py-5 border-2 border-dashed border-primary-500 rounded-lg mt-8 hover:cursor-pointer hover:no-underline hocus:no-underline `}
  .imageContainer {
    ${tw`border-2 border-secondary-800 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-800 text-sm leading-loose`}
  }
`;

// const DecoratorBlob = styled.div`
//   ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
// `;

export default ({data,page}) => {
    let finalData= data?.schema_json;  
    let content= {
        heading: 'Our Professional <span class="text-primary-500">Services</span>',
        open_new_tab:false,
        background_image:"//cdn.shopify.com/s/files/1/0126/2921/3243/files/ProLon_For_Me_-_Ornage_1512x.png?v=1600324658",
        center_all:false,
        // cards:[
        //     {
        //       imageSrc: ShieldIconImage,
        //       title: "Ads Management",
        //       description: "We create and manage ads that you need, from creation to deployment. Lorem ipsum donor sit amet consicou."
        //     },
        //     { imageSrc: SupportIconImage, title: "Video Marketing" },
        //     { imageSrc: CustomizeIconImage, title: "Customer Relation" },
        //     { imageSrc: ReliableIconImage, title: "Product Outreach" },
        //     { imageSrc: FastIconImage, title: "PR Campaign" },
        //     { imageSrc: SimpleIconImage, title: "Product Expansion" }
        //   ],
          cards: [
            {
              image_src: seo,
              title: 'SEO',
              description: 'The foundation of our company! Help Google and Bing identify your website and place you in the search engines top results. We will audit your current website and identify areas of improvement to meet the best-practices of search engines.',
              url:'/pages/seo/'
            },
            {
              image_src: emailMarketing,
              title: 'Email Marketing',
              description: 'Remaining visible to your customers while they are not needing your services is invaluable. We helpestablish enticing email collectors on your site, as well as set up campaigns for newsletters, acquisition, retention, and promotions.',
              url:'/pages/email-marketing/'
            },
            {
              image_src: customization,
              title: 'Customizations',
              description: 'No one size fits here! We understand the need to for customizations on all websites. Templates are lifesavers, we just help you to enhance them! From custom navigation, pages, product pages, carts, to completely custom websites, we can help.',
              url:'/pages/customizations/'
            },
            {
              image_src: affliateMarketing,
              title: 'Affiliate Marketing',
              description: 'We ❤ Affiliates! Affiliates are a wonderful way to grow your business and brand through word of mouth with little to no upfront costs! Affiliate marketing is performance-based and so you only pay for what converts. Sign me up!',
              url:'/pages/affiliate-marketing/'
            },
            {
              image_src: pageSpeed,
              title: 'Pagespeed',
              description: 'Our sweet spot. You’ll hear us refer to this more than you’d like to but that’s only because we see such valuein a good pagespeed. Plain and simple a faster website equals more conversions and isn’t that what we’re all looking for?',
              url:'/pages/pagespeed/'
            },
            {
              image_src: analytics,
              title: 'Data & Analytics',
              description: 'Our word is nothing without the data and analytics to back it up! We love this stuff and utilize it to build a roadmap for success. The more we know about and understand your customer, the better we can appeal and connect with them.',
              url:'/pages/data-analytics/'
            },
            {
              image_src: abTesting,
              title: 'A/B Testing',
              description: 'How do you know what works best? A/B Test it! Sometimes called split testing, it’s a user experience research methodology.A/B testing ensures that data and not personal opinion drive changes to a web page or page component.',
              url:'/pages/ab-testing/'
            },
            {
              image_src: customCart,
              title: 'Custom Carts',
              description: 'Shopping carts, we love them! No, not the germ infested ones you push—The ones online you can customize to slide in, pop open, show stars, entice buyers, create urgency, and make it super easy to checkout, fast.',
              url:'/pages/shopify-cart/'
            },
            {
              image_src: digitalesign,
              title: 'Digital Design',
              description: 'What does your digital design say about you? Digital design is your visual storyboard that when done right can open doors and win over hearts. Finding optimal balance in good looks and functionality to promote customer interaction.',
              url:'/pages/digital-design/'
            },
          ],
       ...finalData
  } 
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  return (
    <Container>
      <ThreeColumnContainer>
      <Heading dangerouslySetInnerHTML={{ __html: content.heading }}>
      {/* <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
        <div dangerouslySetInnerHTML={{ __html: content.heading }}></div>
      </ScrollAnimation> */}
      </Heading>


        {content.cards.map((card, i) => (
          <Column key={i}>
          <ScrollAnimation animateIn='fadeInUp' delay={i*150} animateOnce={true} tw="flex justify-center items-stretch">
            <Card to={card.url}>
              <span className="imageContainer">
                <img src={card.image_src || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
            </ScrollAnimation>
          </Column>
        ))}

      </ThreeColumnContainer>
      {/* <DecoratorBlob /> */}
    </Container>
  );
};
